import { Link } from "gatsby";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import Button from "../General/Button.component";
import PplIkon from "../../images/ppl.svg";
import TelIkon from "../../images/tel.svg";
import EnvIkon from "../../images/env.svg";

const ContactFromStyle = styled.section`
  background: ${({ theme }) => theme.lighterGray};
  padding-top: 4rem;
`;

const ContactFormWrapper = styled.div`
  padding: 5rem 3rem 3rem 3rem;
  background: white;
  margin-bottom: -5rem;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.1);
  display: grid;
  place-items: center;
  .lead {
    font-size: 3.6rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    margin-bottom: 2rem;
  }
  .input-holder {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 2rem;
    div {
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: ${(props) => props.theme.darkGray};
        right: 2rem;
      }
    }
  }
  .input-textarea {
    margin-bottom: 4rem;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: underline !important;
    }
    .acc-wrapper {
      display: inline;
      input {
        width: fit-content;
        margin-right: 2rem;
      }
    }
    @media (max-width: 992px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
  @media (max-width: 992px) {
    margin-bottom: 4rem;
  }
  @media (max-width: 768px) {
    .input-holder {
      grid-template-columns: 1fr;
    }
  }
`;

const ContactForm = ({type}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState(type ? "Típus: "+type+"\n--------------\n\n" : "");

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <ContactFromStyle>
      <div className="container">
        <ContactFormWrapper>
          <div className="lead">Írjon nekünk!</div>
          <form onSubmit={handleSubmit}>
            <div className="input-holder">
              <div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Hogyan szólíthatunk"
                  required
                />
                <PplIkon />
              </div>
              <div>
                <input
                  type="tel"
                  name="tel"
                  id="tel"
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                  placeholder="+36 __ ___ ____"
                  pattern="+36 [0-9]{2} [0-9]{3} [0-9]{4}"
                  required
                />
                <TelIkon />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
                <EnvIkon />
              </div>
            </div>
            <div className="input-textarea">
              <textarea
                name="message"
                id=""
                cols="30"
                rows="10"
                value={message}
                required
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Üzenet"
              ></textarea>
            </div>
            <div className="bottom">
              <div className="acc-wrapper">
                <input
                  type="checkbox"
                  name="acceptance"
                  id="acceptance"
                  required
                  style={{ maxWidth: "2rem" }}
                />
                <label htmlFor="acceptance">
                  Elfogadom az{" "}
                  <Link to="/adatvedelem">adatvédelmi nyilatkozatot</Link> és a{" "}
                  <Link to="/felhasznaloi-feltetelek">
                    felhasználói feltételeket
                  </Link>
                  .
                </label>
              </div>
              <Button styleO={{ bg: "orange" }}>Elküldöm</Button>
            </div>
          </form>
        </ContactFormWrapper>
      </div>
    </ContactFromStyle>
  );
};

export default ContactForm;
