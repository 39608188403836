import React from "react";
import Phone from "../../images/phone.svg";
import Place from "../../images/place.svg";
import Email from "../../images/mail.svg";
import Opening from "../../images/clock.svg";
import styled from "styled-components";

const ContactIcon = styled.div`
  background: ${({ theme }) => theme.orange};
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  display: grid;
  place-items: center;
  min-width: 6rem;
  margin-bottom: 2rem;
  & svg {
    width: 3rem;
    height: 3rem;
  }
`;

const ContactWrapperStyle = styled.div`
  padding: 4rem 0;
  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: 1fr;
    gap: 1rem;
  }
`;

const ContactText = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.darkGray};
  justify-content: center;
  text-align: center;
  align-items: center;
  p {
    font-weight: 900;
    margin-bottom: 1rem;
  }
`;

const ContactItemWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ContactIcons = () => {
  return (
    <div className="container">
      <ContactWrapperStyle>
        <div>
          <ContactItemWrapper>
            <ContactIcon>
              <Phone />
            </ContactIcon>
            <ContactText>
              <p>Telefonszámunk:</p>
              <span>+36 70 604 5559</span>
              <span>+36 30 269 3130</span>
            </ContactText>
          </ContactItemWrapper>
          <ContactItemWrapper>
            <ContactIcon>
              <Place />
            </ContactIcon>
            <ContactText>
              <p>Címünk:</p>
              <span>4002 Debrecen,</span>
              <span>Balmazújvárosi út 10.</span>
            </ContactText>
          </ContactItemWrapper>
          <ContactItemWrapper>
            <ContactIcon>
              <Email />
            </ContactIcon>
            <ContactText>
              <p>Email:</p>
              <span>hello@debrecenablak.hu</span>
            </ContactText>
          </ContactItemWrapper>
          <ContactItemWrapper>
            <ContactIcon>
              <Opening />
            </ContactIcon>
            <ContactText>
              <p>Nyitvatartás:</p>
              <span>Hétfő - Péntek</span>
              <span>8 - 15 óra</span>
            </ContactText>
          </ContactItemWrapper>
        </div>
      </ContactWrapperStyle>
    </div>
  );
};

export default ContactIcons;
