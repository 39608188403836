import React, {useEffect} from "react";
import ContactIcons from "../components/Contact/ContactIcons.component";
import Layout from "../components/General/Layout.component";
import { contactPage } from "../statics/pageInfo.static";
import { genPageInfo } from "../utils/genPageInfo.util";
import IndexPageCTA from "../components/Index/IndexPageCTA.component";
import ContactForm from "../components/Contact/ContactForm.component";
import BC from "../components/General/BC.component";
import queryString from "query-string";

const Kapcsolat = ({ location }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { type } = queryString.parse(location.search);
    return (
    <Layout>
      <BC
        title={"Kapcsolat"}
        sub={[{ text: "Kezdőlap", href: "/" }, { text: "Kapcsolat" }]}
      />
      {genPageInfo(contactPage)}
      <ContactIcons />
      <ContactForm type={type || ''}/>
      <IndexPageCTA />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21544.773630692554!2d21.585305166125025!3d47.546446589576306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4747121e366460eb%3A0xa4a8bb897061e91b!2zRGVicmVjZW4sIEJhbG1hesO6anbDoXJvc2kgw7p0IDEwLCA0MDMx!5e0!3m2!1shu!2shu!4v1644412392159!5m2!1shu!2shu"
        width="100%"
        height="600"
        allowFullScreen=""
        loading="lazy"
        title="GMAP-Debrecenablak"
      ></iframe>
    </Layout>
  );
};

export default Kapcsolat;
