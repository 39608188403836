import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import Button from "../General/Button.component";

const IndexPageCTAContainer = styled.section`
  display: grid;
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .someWrapper {
    display: grid;
    grid-area: 1/1;
    grid-template-columns: 1fr;
  }
`;

const IndexPageCTALead = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 1.5rem;
`;

const IndexPageCTALeadText = styled.div`
  color: white;
  font-family: "Rubik", sans-serif;
  font-size: 10rem;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  height: 10rem;
  top: 10rem;
  opacity: 0.85;
  & p:first-of-type {
    position: absolute;
    height: fit-content;
    width: 10rem;
    left: -6rem;
    top: 4rem;
    font-weight: bold;
    font-family: "Open-Sans", sans-serif;
    font-size: 1.3rem;
    transform: rotate(-90deg);
  }
  & p:last-of-type {
    font-weight: bold;
    font-family: "Open-Sans", sans-serif;
    font-size: 1.8rem;
  }
  @media (max-width: 992px) {
    top: 1rem;
    font-size: 5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    & p:first-of-type {
      position: relative;
      transform: rotate(0);
      left: 0;
      top: 0;
    }
  }
`;

const IndexPageCTAWhiteBox = styled(IndexPageCTALead)`
  position: relative;
  z-index: 1;
  display: grid;
  place-items: end;
  padding-bottom: 10rem;
  @media (max-width: 992px) {
    padding-bottom: 2rem;
  }
`;
const IndexPageCTAWhiteBoxText = styled.div`
  background: white;
  padding: 2rem;
  line-height: 3.2rem;
  margin-right: 5rem;
  @media (max-width: 992px) {
    margin-right: 0;
  }
`;

const IndexPageCTAWhiteBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  bottom: 10rem;
  max-width: 1170px;
  margin: 0 auto;
  @media (max-width: 992px) {
    bottom: 1rem;
    flex-direction: column;
    gap: 2rem;
  }
`;

const IndexPageCTA = () => {
  return (
    <IndexPageCTAContainer>
      <StaticImage
        style={{
          gridArea: "1/1",
        }}
        layout="fullWidth"
        aspectRatio={16 / 9}
        alt=""
        src={"../../images/indexCTA.jpg"}
        formats={["auto", "webp", "avif"]}
      />
      <div className="someWrapper">
        <IndexPageCTALead>
          <div className="container">
            <IndexPageCTALeadText>
              <p>Több mint</p> 20 éve <p>a szakmában</p>
            </IndexPageCTALeadText>
          </div>
        </IndexPageCTALead>
        <IndexPageCTAWhiteBox>
          <div className="container">
            <IndexPageCTAWhiteBoxWrapper>
              <IndexPageCTAWhiteBoxText>
                A gyártást követően a kivitelezési munkákat is vállaljuk, a
                munkáink során mindig betartjuk az ütemezési tervet, ezért
                elégedettek velünk a korábbi partnereink.Nálunk mindig a
                megrendelő az első, rugalmasan alkalmazkodunk az igényeikhez és
                tartjuk a megbeszélt határidőket.
              </IndexPageCTAWhiteBoxText>
              <Button
                callback={() => navigate("/referenciaink")}
                styleO={{ bg: "orange", shadow: true }}
              >
                Nézze meg munkáinkat
              </Button>
            </IndexPageCTAWhiteBoxWrapper>
          </div>
        </IndexPageCTAWhiteBox>
      </div>
    </IndexPageCTAContainer>
  );
};

export default IndexPageCTA;
